import React from "react";
import { t } from "i18next";
import { Component } from "react";
import dayjs from "dayjs";
import { DatePicker, Drawer, Spin, Pagination, Popover, Table,} from "antd";
import { LoadingOutlined, FileExcelOutlined, SyncOutlined } from "@ant-design/icons";
import { numberWithSpaces, withRouter } from "../../utils/GlobalFunctions";
import { getBaseURL } from "../../utils/axiosRequest";
import { saveAs } from 'file-saver';
const { RangePicker } = DatePicker;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      processing: true,
      data: null,
      invoiceInfo: null,
      openInvoiceInfo: false,
      requestFailed: false,
      start: dayjs().startOf("month").format("YYYY-MM-DD"),
      end: dayjs().endOf("month").format("YYYY-MM-DD"),
      sv_name: null,
      sv_status: null,
      pageSize: 20,
      currentPage: 1,
    };
  }

  componentDidMount() {
    //this.setState({start: moment().startOf("month")});
    //this.setState({end: moment().endOf("month")})
    this.getData();
  }

  componentDidUpdate() {}

  getInvoiceInfo = (id, status, page, size) => {
    let query = ``;
    if (status !== undefined) {
      query += `?status=${status}`;
    }
    if (id !== undefined) {
      query += `&form_id=${id}`;
    }
    if (this.state.start !== null) {
      query += `&start_date=${this.state.start} 00:00:00`;
    }
    if (this.state.end !== null) {
      query += `&end_date=${this.state.end} 23:59:59`;
    }
    if (page !== null) {
      query += `&skip=${(page - 1) * size}`;
    }
    if (size !== null) {
      query += `&limit=${size}`;
    }

    fetch(`${getBaseURL()}reports/invoiceinfo${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.setState({ requestFailed: true });
        }
      })
      .then((responseJson) => {
        this.setState({
          invoiceInfo: responseJson,
          processing: false,
          openInvoiceInfo: true,
        });
      });
  };

  getData = () => {
    let query = ``;
    if (this.state.start !== null) {
      query += `?start_date=${this.state.start} 00:00:00`;
    }
    if (this.state.end !== null) {
      query += `&end_date=${this.state.end} 23:59:59`;
    }

    fetch(`${getBaseURL()}reports/medreport${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + window.sessionStorage["access_token"],
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          this.setState({ requestFailed: true });
        }
      })
      .then((responseJson) => {
        this.setState({ data: responseJson, isLoaded: true });
      });
  };

  render() {
    const {
      isLoaded,
      data,
      openInvoiceInfo,
      processing,
      sv_name,
      sv_status,
      currentPage,
      pageSize,
      total_inv,
      formId,
      statusInv,
    } = this.state;
    const { translation } = this.props;

    const columns = [
      {
        title: "Сана",
        width: "10%",
        dataIndex: "invoice_date",
        key: "invoice_date",
        render: (invoice_date) => {
          return (<div className="text-[12px]">{invoice_date}</div>);
        }
      },

      {
        title: "Номгӯи шӯъба",
        width: "15%",
        dataIndex: "department",
        key: "department",
        render: (department) => {
          return (
            <Popover
              overlayStyle={{ maxWidth: "400px" }}
              content={department}
              title="Номгӯи шӯъба"
              trigger="hover"
            >
              <p className="cursor-text mline-clamp">{department}</p>
            </Popover>
          );
        }
      },

      {
        title: "Табиб",
        width: "10%",
        dataIndex: "doctor",
        key: "doctor",
        render: (doctor) => {
          return (<div className="text-[12px]">{doctor}</div>);
        }
      },

      {
        title: "Бемор",
        width: "15%",
        dataIndex: "pacient",
        key: "pacient",
        render: (pacient) => {
          return (<Popover
            overlayStyle={{ maxWidth: "200px" }}
            content={pacient}
            title="Номӯ насаби бемор"
            trigger="hover"
          >
            <p className="cursor-text mline-clamp">{pacient}</p>
          </Popover>);
        }
      },

      {
        title: "Санаи таваллуд",
        width: "10%",
        dataIndex: "dob",
        key: "dob",
        render: (dob) => {
          return (<div className="text-[12px]">{dob}</div>);
        }
      },

      {
        title: "Рақами талон",
        width: "10%",
        dataIndex: "talon",
        key: "talon",
        render: (talon) => {
          return (<div className="text-[12px]">{talon}</div>);
        }
      },

      {
        title: "Сӯроға",
        width: "15%",
        dataIndex: "address",
        key: "address",
        render: (address) => {
          return (<Popover
            overlayStyle={{ maxWidth: "400px" }}
            content={address}
            title="Сӯроға истиқоматии бемор"
            trigger="hover"
          >
            <p className="cursor-text mline-clamp">{address}</p>
          </Popover>);
        }
      },

      {
        title: "Маблағ",
        align: "right",
        dataIndex: "charged_summ",
        key: "charged_summ",
        render: (charged_summ) => {
              return (
                <div className="text-right text-[12px] text-sky-600">
                  {numberWithSpaces((charged_summ).toFixed(2))}
                </div>
              );
            }
      },

    ];

    const invColumns = [
      {
        title: t("INVOICE_NUMBER"),
        dataIndex: "invoice_number",
        key: "invoice_number",
        //...getColumnSearchProps('invoice_number', t('INVOICE_NUMBER').toLowerCase()),
        render: (account) => {
          return <div className="text-[12px]">{numberWithSpaces(account)}</div>;
        },
      },
      {
        title: t("DATE"),
        dataIndex: "create_at",
        key: "create_at",
        width: "12%",
        align: "center",
        render: (date) => {
          return (
            <div className="text-[12px]">
              {dayjs(date).format("DD.MM.YYYY HH:mm")}
            </div>
          );
        },
      },
      {
        title: t("AMOUNT"),
        dataIndex: "amount",
        key: "amount",
        width: "10%",
        align: "center",
        render: (amount) => {
          return (
            <div className="text-[12px]">
              {numberWithSpaces((amount / 100).toFixed(2))}
            </div>
          );
        },
      },
      {
        title: t("PAYER"),
        dataIndex: "fullName",
        key: "fullName",
        //...getColumnSearchProps('fullName', t('PAYER').toLowerCase()),
        render: (name) => {
          return <div className="uppercase text-[12px]">{name}</div>;
        },
      },
      {
        title: t("APPLICANT_CONTACTS"),
        dataIndex: "customer_phonenumber",
        key: "customer_phonenumber",
        align: "center",
        //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
        render: (customer_phonenumber) => {
          return <div className="text-[12px]">{customer_phonenumber}</div>;
        },
      },
      {
        title: t("PERFORMER"),
        dataIndex: "performer",
        key: "performer",
        align: "center",
        //...getColumnSearchProps('customer_phonenumber', t('APPLICANT_CONTACTS').toLowerCase()),
        render: (performer) => {
          return <div className="text-[12px]">{performer}</div>;
        },
      }
    ];

    return (
      <div className="flex w-full justify-center">
        {!isLoaded ? (
          <Spin indicator={antIcon} />
        ) : (
          <div className="block m-2">
            <div className="flex justify-between w-full m-2 sm:flex-col md:flex-col lg:flex-row xl:flex-row sm:items-start md:items-start lg:items-center xl:items-center sm:space-x-0 md:space-x-0 lg:space-x-6 xl:space-x-6 sm:space-y-3 md:space-y-3 lg:space-y-0 xl:space-y-0">
              <div className="flex items-center">
              <div className="m-4 text-sky-800">Давраи ҳисобот</div>
              <RangePicker
                defaultValue = {[dayjs().startOf("month"), dayjs().endOf("month")]}
                onCalendarChange={(val) => {
                  this.setState({ start: dayjs(val[0]).format("YYYY-MM-DD") });
                  this.setState({ end: dayjs(val[1]).format("YYYY-MM-DD") });
                }}
                onOpenChange={(status) => {
                  if (!status) {
                    this.getData();
                  }
                }}
              />
            </div>

  {data ? (
    <div className="flex items-center pr-4">
          <div className="m-4 text-sky-800">Зеркашии ҳисобот</div>
          <button className="flex bg-green-600 text-white mb-2 lg:text-md xl:text-lg 
            sm:px-3 sm:py-2 md:px-3 lg:px-5 xl:px-10 md:py-2 lg:py-2 xl:py-2 
            whitespace-nowrap rounded-[8px] items-center"
            disabled={this.state.excelDisabled}

            onClick={()=>{
            this.setState({ excelDisabled: true });
            fetch(`${getBaseURL()}reports/med_excel?start_date=${this.state.start} 00:00:00&end_date=${this.state.end} 23:59:59`,
            { 
                method: "GET",
                headers: { "Content-Type": "application/json",'Authorization': 'Bearer ' + window.sessionStorage["access_token"]},
                
            }).then(async response => {
              
              if(response.status === 200){
                let filename = response.headers.get('content-disposition');
                filename = filename.split('"')[1];
                const blob = await response.blob();
                saveAs(blob, filename);
                window.alert(`Файли омор ${filename} бор карда шуд.` ); 
              
                  
              } else {
                window.alert(`Дархости хато` )
                
              }

              this.setState({ excelDisabled: false })
              
            })
          }}
          >

            <div className="flex items-center">
              <div className="flex mr-4 items-center">
                {this.state.excelDisabled 
                  ? <SyncOutlined style={{ fontSize: 28, color: "#fff"}} spin />
                  : <FileExcelOutlined color="#fff" />
                }
              </div>
              <div>Excel</div>
            </div>
          </button>
          </div>
          ):(<></>)
        }
            </div>

            <Table
              columns={columns}
              dataSource={data}
              size="small"
              sticky
              bordered
              pagination={false}
              summary={(pageData) => {
                let totalCharged_summ = 0;
                let totalOpen_summ = 0;
                let totalCharged_qty = 0;
                let totalOpen_qty = 0;
                pageData.forEach(
                  ({ charged_summ, open_summ, charged_qty, open_qty }) => {
                    totalCharged_qty += charged_qty;
                    totalOpen_qty += open_qty;
                    totalCharged_summ += charged_summ;
                    totalOpen_summ += open_summ;
                  }
                );

                return (
                  <>
                    
                   <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={7}>
                        <div className="font-semibold text-right">
                          {translation("TOTAL_AMOUNT")}
                        </div>
                      </Table.Summary.Cell>
                      

                      <Table.Summary.Cell index={7}>
                        <div className="font-semibold text-right">
                          {numberWithSpaces(totalCharged_summ.toFixed(2))}
                        </div>
                      </Table.Summary.Cell>
                      
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={7}>
                        <div className="font-semibold text-right">
                          Хизматрасонии бонки/эквайринг
                        </div>
                      </Table.Summary.Cell>
                      

                      <Table.Summary.Cell index={7}>
                        <div className="font-semibold text-right">
                          {numberWithSpaces(( 0 - totalCharged_summ * 0.01).toFixed(2))}
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                      <Table.Summary.Cell index={0} colSpan={7}>
                        <div className="font-semibold text-right">
                          Ба суратҳисоби бонки интиқол шуд
                        </div>
                      </Table.Summary.Cell>

                      <Table.Summary.Cell index={7}>
                        <div className="font-semibold text-right">
                          {numberWithSpaces(( totalCharged_summ - totalCharged_summ * 0.01).toFixed(2))}
                        </div>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />

            <Drawer
              className="w-full"
              placement="top"
              height="100%"
              open={openInvoiceInfo}
              onClose={() => {
                this.setState({ openInvoiceInfo: false });
              }}
              style={{ overflowX: "hidden" }}
              bodyStyle={{ paddingBottom: 80 }}
              getContainer={false}
              destroyOnClose={true}
            >
              {processing ? (
                <Spin indicator={antIcon} />
              ) : (
                <div className="h-[120vh] w-full">
                  <div className="font-semibold">{sv_name}</div>
                  <div className="mb-4 font-semibold">{sv_status}</div>
                  <Pagination
                    className="mt-2 ml-4"
                    onChange={(page, size) => {
                      this.setState({ currentPage: page, pageSize: size });
                      this.getInvoiceInfo(formId, statusInv, page, size);
                    }}
                    onShowSizeChange={(page, size) => {
                      this.setState({ currentPage: page, pageSize: size });
                      this.getInvoiceInfo(formId, statusInv, page, size);
                    }}
                    total={Math.ceil(total_inv)}
                    current={currentPage}
                    pageSize={pageSize}
                    hideOnSinglePage={true}
                  />
                  <Table
                    columns={invColumns}
                    dataSource={this.state.invoiceInfo}
                    bordered
                    pagination={false}
                  ></Table>
                </div>
              )}
            </Drawer>
          </div>
        )}
        
        {/*<div className="block text-center">
                <div className="text-[24px]">Саҳифаи "Ҳисобот"</div>
                <div className="text-[24px]">{translation('UNDER_CONSTRUCTION')}</div>
              </div>*/}
      </div>
    );
  }
}

export default withRouter(Reports);
