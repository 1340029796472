import React, { Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./view/Login/Login";
import Spinner from "./components/ui/Spinner";
import PaymentList from "./view/Invoices/PaymentList";
//import SmartPayLayout from "./layout/SmartPayLayout";
import FillForm from "./view/Invoices/FillForm";
import PaymentForms from "./view/Invoices/PaymentForms";
import InvoiceView from "./view/Invoices/InvoiceView";
import OperatorLayout from "./layout/OperatorLayout";
import Reports from "./view/Reports/Reports";


function App () {
return (<>
            
<Routes>
    <Route path="/" element={<Login />} />
    <Route path="/" element={<OperatorLayout />} >
        
        <Route path="payments" element={
            <Suspense fallback={<Spinner/>}>
                <PaymentList />
            </Suspense>
        }></Route>
        
        <Route path="payments/forms" element={
            <Suspense fallback={<Spinner/>}>
                <PaymentForms />
            </Suspense>
        }></Route>
        
        <Route path="payments/forms/:formId" element={
            <Suspense fallback={<Spinner/>}>
                <FillForm />
            </Suspense>
        }></Route>
        
        <Route path="payments/invoice/:invoiceId" element={
            <Suspense fallback={<Spinner/>}>
                <InvoiceView />
            </Suspense>
        }></Route>

        {<Route path="reports" element={
            <Suspense fallback={<Spinner/>}>
                < Reports />
            </Suspense>
        }></Route>}
    </Route>
    
    </Routes>
    </>
    )
}

export default App;